import React,{useState, useEffect} from "react";
import { useSelector } from 'react-redux';
import { makeInfoChart } from "../utils";
import Chart from 'react-apexcharts'

function InfoFondos(){
    const appData = useSelector((state) => state.appData)

    const [infoChart, setInfoChart] = useState(false);


    return (
        <div className="row">
                <div className="col s12 m5 l5 xl5">
                    <div className="row">
                        <div className="col s12 closeModalOk left-align boldText ft-20 titleFondos">
                            Información de Fondos
                        </div>
                        {
                            appData.infoFondos ?

                               <div className="col s12 contentInfoFondos">
                                    {
                                        Object.keys(appData.infoFondos.infoFondos).map((v, i) => {
                                            return (
                                                <React.Fragment key={i}>
                                                    <div className="col s12 mt-1 mb-1 txtBold" >
                                                        {v}
                                                    </div>
                                                    {
                                                        appData.infoFondos.infoFondos[v].map((inf, j) => {
                                                            return (
                                                                <div className={`col s12 infoIRow cursorPointer`} key={j}
                                                                    onClick={() => {
                                                                        // console.log(inf)
                                                                        // this.makeInfoShart(inf);
                                                                        setInfoChart(makeInfoChart(inf));
                                                                    }}>
                                                                    <div className="row ">
                                                                        <div className="col s6 left-align vlmx-navy-80 ft-14">
                                                                            {inf.Fondo}
                                                                        </div>
                                                                        <div className="col s6 right-align vlmx-navy-80">
                                                                            {inf.PrecioMD}
                                                                        </div>
                                                                        <div className="col s6 left-align vlmx-navy-80">
                                                                            <label>{inf.Operacion}</label>
                                                                        </div>
                                                                        <div className="col s6 right-align vlmx-navy-80">
                                                                            <label>{inf.Plazo}</label>
                                                                        </div>
                                                                        <div className="col s12">
                                                                            <div className="divider" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </React.Fragment>
                                            )
                                        })
                                    }

                                </div>

                                : null
                        }

                    </div>
                </div>

                <div className="col s12 m7 l7 xl7" id="chart">
                    {
                        infoChart.info ?
                            <div className="row mt-1 chartInfo">
                                <div className="col s6 left-align txtBold ft-16 mt-1">
                                    Detalle de Fondos
                                </div>
                                <div className="col s6 right-align ft-16 mt-1">
                                    <i className="material-icons cursorPointer"
                                    onClick={()=>{
                                        setInfoChart(false);
                                    }}
                                    >close</i>
                                </div>
                                <div className="col s12">
                                <div className="divider"/>
                                </div>
                                <div className="col s12 left-align ml-1-4">
                                    <p className="txtBold">{ infoChart.info.Fondo}</p>
                                    <p>Último precio conocido <span className="boldText">${ infoChart.info.preciosMes[ infoChart.info.preciosMes.length - 1].Precio}</span> </p>
                                    <p>Fecha de liquidación <span className="boldText">{ infoChart.info.preciosMes[ infoChart.info.preciosMes.length - 1].Fecha.replace(/-/g, '/')}</span></p>
                                </div>

                                <div className="col s12">
                                    <Chart options={infoChart.options} series={infoChart.series} type="line" height={350} />
                                </div>

                                <div className="col s12 mt-1">
                                    <div className="row notificacionContainer contentInfoFondo">
                                        <div className="col s6">
                                            <div><label>Tipo de gestión</label></div>
                                            <div>{ infoChart.info.TipoGestion}</div>
                                        </div>
                                        <div className="col s6">
                                            <div><label>Horizonte de Inversión</label></div>
                                            <div>{ infoChart.info.Plazo}</div>
                                        </div>
                                        <div className="col s12">
                                            <div><label>Liquidez</label></div>
                                            <div>{infoChart.info.Liquidacion}</div>
                                        </div>
                                        <div className="col s6">
                                            <div><label>Hora de apertura</label></div>
                                            <div>{ infoChart.info.HApertura}</div>
                                        </div>
                                        <div className="col s6">
                                            <div><label>Hora de cierre</label></div>
                                            <div>{ infoChart.info.HCierre}</div>
                                        </div>
                                        <div className="col s12">
                                            <p className="borderInfoF borderInfoFT paddingTopBI" >
                                                <label>
                                                    <input type="checkbox" className="filled-in" checked={
                                                         infoChart.info.CpaAbierta
                                                    } />
                                                    <span>Compra abierta</span>
                                                </label>
                                            </p>
                                            <p className="borderInfoF minusMT-9">
                                                <label>
                                                    <input type="checkbox" className="filled-in" 
                                                    checked={
                                                        infoChart.info.VtaAbierta
                                                    }
                                                    />
                                                    <span>Venta abierta</span>
                                                </label>
                                            </p>
                                            <p className="borderInfoF minusMT-9">
                                                <label>
                                                    <input type="checkbox" className="filled-in" 
                                                    checked={
                                                        infoChart.info.FondoAbierto
                                                    } />
                                                    <span>Fondo abierto</span>
                                                </label>
                                            </p>
                                        </div>
                                        <div className="col s12">
                                            <div><label>Valor a mercado mismo día</label></div>
                                            <div>${ infoChart.info.PrecioMD}</div>
                                        </div>
                                        <div className="col s6">
                                            <div><label>Valor a mercado 24 horas</label></div>
                                            <div>${ infoChart.info.Precio24}</div>
                                        </div>
                                        <div className="col s6">
                                            <div><label>Valor a mercado 48 horas</label></div>
                                            <div>${ infoChart.info.Precio48}</div>
                                        </div>
                                        <div className="col s12">
                                            <div><label>Descripción del Fondo:</label></div>
                                            <div>{ infoChart.info.Descripcion}</div>
                                        </div>
                                        <div className="col s12 mt-1">
                                            <label className="ft-12">*Para más detalles consulta la página Operadora de Fondos Valmex</label>
                                        </div>
                                      
                                    </div>
                                </div>

                            </div>

                            : null}

                </div>
              
            </div>
    )
}


export default InfoFondos;