import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import calbuladoraBtn from '../assets/CalculatorBtn.svg'

function EducacionFinanciera() {
    const appData = useSelector((state) => state.appData);
    const [content, setContent] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        document.body.classList.add('bodyUser')

        return () => {
            document.body.classList.remove('bodyUser')
            document.body.classList.add('bodyLogin')
        }
    }, [])


    useEffect(()=>{
        if(appData.educacionFinanciera && appData.educacionFinanciera.length){
            setContent(appData.educacionFinanciera[0])
        }

    },[appData.educacionFinanciera])


    return (
        <div className="container">
            <div className="row">
                <div className="col s12 m9 l9 xl9 left-align mt-2">
                    <p className='cursorPointer'
                        onClick={() => {
                            navigate('/login')
                        }}
                    ><i className='material-icons left'>keyboard_backspace</i> Log in</p>
                    <h5 className="txtBold">Educación Financiera</h5>
                </div>
                <div className="col s12 m3 l3 xl3 right-align mt-2">
                    <img src={calbuladoraBtn} alt='Calcular Retiro' className="btnCalculoRetiro waves-effect"
                    onClick={()=>{
                        navigate('/calcular_retiro');
                    }}
                    />
                </div>
                {
                    appData.educacionFinanciera && appData.educacionFinanciera.length ?
                        <div className="col s12 mt-2">
                            <div className="row ">
                                <div className="col s3">
                                    <div className="row btnSide">

                                        {
                                          appData.educacionFinanciera.map((v,i)=>{
                                            return(
                                                <div className="col s12" key={i}>
                                                    <button className={`btn waves-effect light btnAct white ${content.titulo === v.titulo ? 'optSelected' : ''} ft-14`}
                                                        key={i}
                                                        onClick={() => {
                                                            setContent(v)
                                                        }}
                                                    >{v.titulo}</button>
                                                </div>
                                            )
                                          })
                                        }
                                    </div>
                                </div>
                                {
                                    content ? 
                                    <div className="col s9 paddingEduFin">
                                        <div className="row wrapperEduFin">
                                            <div className="col s12 left-align ft-16 txtBold">
                                                {content.titulo}
                                            </div>
                                            <div className="col s12 center-align mt-1">
                                                <img src={content.imagen} alt={content.titulo}  className="responsive-img"/>
                                            </div>
                                            <div className="col s12 left-align ft-16 txtBold mt-1" >
                                            {content.subtitulo}
                                            </div>
                                            <div className="col s12 left-align ft-16 mt-1 txtJustify contentLegal">
                                            { decodeURIComponent(escape(atob( content.contenido )))}
                                            </div>
                                        </div>
                                    </div>

                                    : null
                                }
                            </div>
                        </div>

                        : null
                }
            </div>
        </div>
    )

}

export default EducacionFinanciera;