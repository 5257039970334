

import { useSelector } from 'react-redux';
import { TXT_NO_DISPONIBLE, getAntiguedad } from '../utils'
import { useEffect, useState } from 'react';

function ProfileData() {

    const appData = useSelector((state) => state.appData)
    const [userData, setUserData] = useState({empresa:false,email:false, telefono: false})

    useEffect(()=>{
        if(appData.user){
            getUserData(appData.user);
        }
    },[])

    function getUserData(user){
        let encontrado = false;

        if(user.Productos && user.Productos.length){
            for(let producto of user.Productos){

                if(!encontrado){
                    if(producto.TipoCliente.toUpperCase() === 'FONDO DE AHORRO'
                    || producto.TipoCliente.toUpperCase() === 'FONDO DE PENSIONES'
                    || producto.TipoCliente.toUpperCase() === 'CAJA DE AHORRO'){
                        let tel = false;

                        if(producto.Telefono.trim() !== '' && producto.Telefono !== '*****'){
                            tel = producto.Telefono;     
                        }
                        setUserData({
                            email: producto.email,
                            empresa: producto.Empresa,
                            telefono: tel
                        });
                        encontrado = true;
                    }

                  
                }
              
            }
        }
    }

 
    return (
        <div className="row">
            <div className="col s12 left-align ft-18 txtBold">
                Perfil
            </div>
            <div className="col s12 mt-1 left-align wrapDataProfile brT brB">
                <p className="ft-14 fw-300 labelNavy">Nombre</p>
                <p className="ft-16 fw-400 labelNavy">{appData.user.Nombre + " " + appData.user.ApPaterno}</p>
            </div>
            <div className="col s12 mt-1 left-align wrapDataProfile mt-n-2 brB">
                <p className="ft-14 fw-300 labelNavy">Correo</p>
                <p className="ft-16 fw-400 labelNavy">{userData.email ? userData.email : TXT_NO_DISPONIBLE}</p>
            </div>
            <div className="col s12 mt-1 left-align wrapDataProfile mt-n-2 brB">
                <p className="ft-14 fw-300 labelNavy">Teléfono</p>
                <p className="ft-16 fw-400 labelNavy">{userData.telefono ? userData.telefono : TXT_NO_DISPONIBLE}</p>
            </div>
            <div className="col s12 mt-1 left-align wrapDataProfile mt-n-2 brB">
                <p className="ft-14 fw-300 labelNavy">Número de empleado</p>
                <p className="ft-16 fw-400 labelNavy">{appData.user.Clave}</p>
            </div>
            <div className="col s12 mt-1 left-align wrapDataProfile mt-n-2 brB">
                <p className="ft-14 fw-300 labelNavy">Empresa</p>
                <p className="ft-16 fw-400 labelNavy">{userData.empresa ? userData.empresa : TXT_NO_DISPONIBLE}</p>
            </div>{
                getAntiguedad(appData.user) ?
                    <div className="col s12 mt-1 left-align wrapDataProfile mt-n-2 brB">
                        <p className="ft-14 fw-300 labelNavy">Años de antigüedad</p>
                        <p className="ft-16 fw-400 labelNavy">{getAntiguedad(appData.user)}</p>
                    </div>
                    : null
            }

        </div>
    )
}

export default ProfileData;