export const BASE_URL = 'https://us-central1-valmex-si-qa.cloudfunctions.net'
export const KEY='CL#AvEPrincIp4LvA#lMEXapgpsi2020'
export const IV ='v3cT*r1niTaPpS1+'

export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyDBoQYM0PlDRxEodR1GIe3Uvv19FTz4xz4",
    authDomain: "valmex-si-qa.firebaseapp.com",
    databaseURL: "https://valmex-si-qa.firebaseio.com",
    projectId: "valmex-si-qa",
    storageBucket: "valmex-si-qa.appspot.com",
    messagingSenderId: "958052009163",
    appId: "1:958052009163:web:ee54c8b32d321deaa8c74e",
    measurementId: "G-VCQ1EF6HQD"
  }


export const usuarioFondos = '/FxqyNREto2Ca6vXFlEhaQ==';

export const CALCULADORA_73 = 'https://us-central1-valmex-si-qa.cloudfunctions.net/simuladorPension/calculadora73';
export const CALCULADORA_97 = 'https://us-central1-valmex-si-qa.cloudfunctions.net/simuladorPension/calculadora97';