
import { Link } from "react-router-dom";
import FormNewPass from "../components/formNewPass";

function CambioPass() {

    return (
        <div className="row ml-10">
            <div className="col s12 left-align">
              <Link to="/perfil/seguridad" className="ft-13 txt_main-dark-blue fw-400">Regresar <i className="material-icons left ft-20">arrow_back</i></Link>
            </div>
            <div className="col s12 left-align ft-18 mt-1">
                Cambiar Contraseña
            </div>
            <div className='col s12 mt-1'>
                <div className='row'>
                    <div className="container white center-align p-10 wPP">
                        <FormNewPass />
                    </div>
                    
                </div>
            </div>
        </div>
    )

}

export default CambioPass;