import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getClassBtn } from '../utils';



function Products() {
    const appData = useSelector((state) => state.appData)
    const [content, setContent] = useState({ titulo: "", descripcion: "" })
    const [contentProduct, setContentProduct] = useState(false)
    const [btnS, setBtnS] = useState('')

    useEffect(() => {

        if (appData.productosInfo && Object.keys(appData.productosInfo).length) {
            let obj = appData.productosInfo[Object.keys(appData.productosInfo)[0]]
            setBtnS(obj.Titulo);
            setContentProduct(obj);
        }

    }, [])

    return (
        <div className="col s12 left-align wrapDataProfile brT brB ln1_5">
            <p className='boldText'>Productos</p>
            <div className='row paddingLeftBotones'>
                <div className='col s12 lef-align mt-1'>
                    <div className='row botonesProductos'>
                        {
                            Object.keys(appData.productosInfo).map((v, i) => {
                                return (
                                    <div className='col s12 m12 l4 xl4 center-align margenBotonesProd ' key={i}>
                                        <button className={`btn ${getClassBtn(v, btnS)} ft-14 boldText br-12 txt_transformNone`}
                                            onClick={() => {
                                                setBtnS(appData.productosInfo[v].Titulo)
                                                setContentProduct(appData.productosInfo[v])
                                            }}
                                        > {appData.productosInfo[v].Titulo} </button>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='col s12 paddingLeft0'>
                    {contentProduct.Descripcion}
                </div>
                <div className='col s12'>
                    <div className='row'>
                        {
                            contentProduct.Contenido && contentProduct.Contenido.map((v, i) => {
                                if (v.tipo === 'SubTitulo') {
                                    return (
                                        <div className='col s12 center-align mt-1 txtBold mb-1'>
                                            {v.texto}
                                        </div>
                                    )
                                } else {
                                    return (

                                        <li className='ProductInfo mt-1'>
                                            {v.texto}
                                        </li>

                                    )
                                }
                            })
                        }
                    </div>
                </div>

            </div>
        </div>
    )

}


export default Products;