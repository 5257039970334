import { useSelector } from 'react-redux';

function Accounts() {
    const appData = useSelector((state) => state.appData)

    return (
        <div className="row">
            <div className="col s12 left-align ft-18 txtBold">
                Cuentas Registradas
            </div>
            <div className='col s12'>
                <div className='row'>
                    {
                        appData.user && appData.user.Productos ?
                            appData.user.Productos.map((p, i) => {
                                return (
                                    <div className='col s12 wrapDataAccounts mt-1'>
                                        <div className='row fw-400 mb-0'>
                                            <div className='col s12 ft-14 ml-10'>
                                                {p.TipoCliente}
                                            </div>
                                            <div className='col s12 row contentInfoAccount mb-0'>
                                                <div className='col s4 left-align ft-16 pl-0'>
                                                    {p.Banco}
                                                </div>
                                                <div className='col s4 center-align ft-14 pl-0'>
                                                    {p.Clabe}
                                                </div>
                                                <div className='col s4 right-align truncate ft-16'>
                                                    {`${appData.user.Nombre} ${appData.user.ApPaterno}`}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            : null
                    }
                </div>
            </div>
        </div>
    )

}

export default Accounts;