import { getFirestore, doc, getDoc, collection, getDocs, addDoc, updateDoc, setDoc } from 'firebase/firestore'
import { compararPorFecha,  } from '../utils';
import {usuarioFondos} from '../properties'

export const setBitacora = async ({ data, idUsuario }) => {
    const db = getFirestore();

    try {
        await addDoc(collection(db, `/AccesosApp/${idUsuario}/accesosWeb`), data)
    } catch (error) {
        //console.log(error)
    }
}

export const getMostrarSaldo = async (idUsuario) => {
    const db = getFirestore();

    try {
        let data = await getDoc(doc(db, `/usuarios/${idUsuario}/configuraciones/seguridad`))
        if (data.exists()) {

            if (data.data().hasOwnProperty('mostrarSaldo')) {
                return data.data().mostrarSaldo;
            } else {
                return true;
            }
        } else {
            return true;
        }
    } catch (error) {
        //console.log(error)
    }
}

export const setMostrarSaldo = async (mostrarSaldo, idUsuario) => {
    const db = getFirestore();

    try {
        let data = await getDoc(doc(db, `/usuarios/${idUsuario}/configuraciones/seguridad`))
        if (data.exists()) {
            await updateDoc(doc(db, `/usuarios/${idUsuario}/configuraciones/seguridad`), {
                mostrarSaldo: mostrarSaldo
            })
        } else {
            await setDoc(doc(db, `/usuarios/${idUsuario}/configuraciones/seguridad`), {
                mostrarSaldo: mostrarSaldo
            })
        }
    } catch (error) {
        
    }
}

export const getBitacoraAll = async ({ idUsuario, path }) => {
    const db = getFirestore();

    try {
        const docs = await getDocs(collection(db, `/AccesosApp/${idUsuario}/${path}`));
        if (docs.empty) {
            return [];
        } else {
            let arr = [];
            for (const doc of docs.docs) {

                try{
                    if (path === 'accesosWeb') {
                        let d = doc.data()
                        d.path = path;
                        arr.push(d);
                    } else {
    
                        let dp = doc.data();
    
                        if(dp.latitude && dp.longitud){
                            if (dp.fecha && dp.lugar) {
                                arr.push(doc.data());
                            } else {
    
                                if(dp.hasOwnProperty('date')){
                                    dp.fecha = dp.date.toDate().getTime();
                                }
                                
                                dp.id = doc.id;
                                dp.lugar = `${dp.ciudad} ${dp.estado} ${dp.pais}`
    
                                if(dp.hasOwnProperty('plataforma') && dp.plataforma.trim() !== ''){
                                    dp.navegador = dp.plataforma;
                                }else{
                                    if(path.includes('IOS')){
                                        dp.navegador = 'IOS'
                                    }else if(path.includes('ANDROID')){
                                        dp.navegador = 'Android'
                                    }
                                }
                                arr.push(dp);
                            }
                        }

                    }
                }catch(e){
        
                }
               
            }
            return arr.sort(compararPorFecha);
        }

    } catch (error) {

        return [];
    }
}

export const getBitacora = async ({ idUsuario }) => {

    let obj = {}
    try {

        let paths = ["accesosWeb", "accesosAndroid", "accesosIOS"];

        for (const path of paths) {
            obj[path] = await getBitacoraAll({ idUsuario, path });
        }

       
        return obj;

    } catch (error) {
        return obj;
    }
}

export const getNotificaciones = async (idUsuario) => {
    const db = getFirestore();

    const docRef = doc(db, 'NotificacionesPush', idUsuario);

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        const data = docSnap.data();

        if (data.Notificaciones) {
            return data.Notificaciones;
        }
    }

    return [];
}


export const getLegales = async () => {
    try {
        const db = getFirestore();

        const docsf = await getDocs(collection(db, 'Legales'))

        if (docsf.docs.length) {
            let obj = {}
            for (const document of docsf.docs) {
                obj[document.id] = document.data()
            }

            return obj
        } else {
            return null;
        }
    } catch (error) {

        return null;
    }
}

export const getPreguntas = async () => {
    let obj = {}
    try {
        const db = getFirestore();

        const docsfConocenos = await getDocs(collection(db, '/Preguntas/conocenos/preguntas'))

        if (docsfConocenos.docs.length) {
            let conocenos = []
            for (const document of docsfConocenos.docs) {
                conocenos.push(document.data())
            }

            obj['conocenos'] = conocenos.sort((a, b) => a.posicion - b.posicion)
        }


        const docsfFaqs = await getDocs(collection(db, '/Preguntas/faqs/preguntas'))

        if (docsfFaqs.docs.length) {
            let faqs = []
            for (const document of docsfFaqs.docs) {
                faqs.push(document.data())
            }

            obj['faqs'] = faqs.sort((a, b) => a.posicion - b.posicion)
        }


        return obj
    } catch (error) {

        return null;
    }
}

export const getProductosInfo = async () => {
    try {
        const db = getFirestore();
   
        const docsPreguntas = await getDocs(collection(db, '/Productos'))

        if (docsPreguntas.docs.length) {
            let obj = {}
            for (const document of docsPreguntas.docs) {

                obj[document.id] = document.data();
            }

            return obj;
        }
        return null
    } catch (error) {
      
        return null;
    }

}


export const getDatosInactividad = async () => {
    const db = getFirestore();

    try {
        let data = await getDoc(doc(db, `/configuracion/appActivityConfig`))
        if (data.exists()) {

            return data.data();
        } else {
            return {
                timerInactividad: 5,
                timerSesion: 20
            };
        }
    } catch (error) {
        //console.log(error)
    }
}

export const getKeyInfoFondo = async () => {
    const db = getFirestore();

    try {
        let data = await getDoc(doc(db, `/configuracion/usuarioServicioKey`))
        if (data.exists()) {
            return data.data().key;
        } else {
            return usuarioFondos;
        }
    } catch (error) {
        //console.log(error)
    }
}


export const getEducacionFinanciera = async () => {
    try {
        const db = getFirestore();

        const docsEduFinanciera = await getDocs(collection(db, '/Preguntas/informacion_financiera/tips'))

        if (docsEduFinanciera.docs.length) {
            let obj = []
            for (const document of docsEduFinanciera.docs) {
                obj.push(document.data());
            }

            obj = obj.sort((a, b) => a.posicion - b.posicion)

            return obj;
        }
        return null
    } catch (error) {
        return null;
    }
}