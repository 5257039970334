

import React from "react";
import { Outlet } from "react-router";
import Logo_H from '../assets/horizontal.svg'
import Logo from '../assets/logo.svg'
import { Link,useNavigate } from "react-router-dom";
import Avatar from 'react-avatar';

export const NavNoSession = () => {
    const navigate = useNavigate();



    return (

        <React.Fragment>
          
            <div className="row navHome">
                <div className="col s10 ">
                    <Link to="/" className="brand-logo">
                        <img src={Logo_H} alt="Valmex Si" className="responsive-img logoNav" />
                        <img src={Logo} alt="Valmex Si" className="responsive-img logoNav_m " />
                    </Link>
                </div>
                <div className="col s2 cursorPointer"
                onClick={()=>{
                    navigate('/')
                }}
                >
                    <Avatar name={'S N'} round={true} size="40" className="avatarText" color="#8ADAE5" />
                    <span className="hide-on-med-and-down"> Log in</span> <i className="material-icons chProfile hide-on-med-and-down">
                    expand_more
                    </i>
                </div>
            </div>
            <div className="wrapperOutlet">
                <Outlet />
            </div>
        </React.Fragment>
    )


}

