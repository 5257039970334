import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import M from 'materialize-css'
import {toTitleCase, getClassBtn} from '../utils';

function Faqs() {
    const appData = useSelector((state) => state.appData);
    const [content, setContent] = useState(false);
    const [selectOpt, setOpt] = useState(false);
    const [indexOpt, setIndexOpt] = useState(0);
    const [faqs, setFaqs] = useState(false);
    const [btnS, setBtnS] = useState('')
    const navigate = useNavigate();

    useEffect(() => {
        document.body.classList.add('bodyUser')

        return () => {
            document.body.classList.remove('bodyUser')
            document.body.classList.add('bodyLogin')
        }
    }, [])

    useEffect(() => {
        if (selectOpt && selectOpt !== 'PRODUCTOS') {
            setContent(faqs[selectOpt])
        }else if(selectOpt && selectOpt === 'PRODUCTOS'){
            let keys = Object.keys(faqs[selectOpt]);
            setContent(faqs[selectOpt][keys[0]])
            setBtnS(keys[0])
        }
    }, [selectOpt])

    useEffect(() => {
        M.Collapsible.init(document.querySelectorAll('.collapsible'), {});
    }, [content])

    useEffect(() => {
        if (appData.preguntas && appData.preguntas.faqs) {

            let obj = {
                PRODUCTOS: {}
            }

            for (const v of appData.preguntas.faqs) {
                if (v.categoria.toUpperCase() === 'FONDO DE AHORRO'
                    || v.categoria.toUpperCase() === 'PLAN DE PENSIONES'
                    || v.categoria.toUpperCase() === 'CAJA DE AHORRO'
                ) {
                    if (obj.PRODUCTOS[v.categoria]) {
                        obj.PRODUCTOS[v.categoria].push(v)
                    } else {
                        obj.PRODUCTOS[v.categoria] = [v]
                    }
                    obj.PRODUCTOS[v.categoria].sort((a, b) => a.posicion - b.posicion)

                } else if (obj[v.categoria]) {
                    obj[v.categoria].push(v)
                } else {
                    obj[v.categoria] = [v]
                    obj[v.categoria].sort((a, b) => a.posicion - b.posicion)
                }
            }
   
            setFaqs(obj)
            setContent(obj['PRODUCTOS']['FONDO DE AHORRO'])
            setOpt('PRODUCTOS')
           
        }
    }, [appData.preguntas])

    function getText(text){
        try {
            return decodeURIComponent(escape(atob( text )))
        } catch (error) {
            return text
        }
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col s12 left-align mt-2">
                    <p className='cursorPointer mw-100'
                        onClick={() => {
                            navigate('/login')
                        }}
                    ><i className='material-icons left'>keyboard_backspace</i> Log in</p>
                    <h5 className="txtBold">Preguntas Frecuentes</h5>
                </div>
                {
                    appData.preguntas ?

                        <div className="col s12 mt-2">
                            <div className="row ">
                                <div className="col s3">
                                    <div className="row btnSide">

                                        {
                                            faqs ?
                                                Object.keys(faqs).map((v, i) =>  <div className="col s12" key={i}>
                                                    <button className={`btn waves-effect light btnAct white ${indexOpt === i ? 'optSelected':''} ft-14`}
                                                        key={i}
                                                        onClick={() => {
                                                            setOpt(v)
                                                            setIndexOpt(i)
                                                        }}
                                                    >{toTitleCase(v)}</button>
                                                </div> )
                                                : null
                                        }
                                    </div>
                                </div>
                                <div className="col s9">
                                    <div className="row">
                                        <div className='col s12 left-align boldText ft-14'>
                                            {toTitleCase(selectOpt)}
                                        </div>
                                        <div className="col s12 left-align brT brB ln1_5 mt-1">
                                            {
                                                selectOpt && selectOpt !== 'PRODUCTOS' ?
                                                    <ul className="collapsible noBoxShadow br-8 white" >
                                                        {
                                                            content && content.map((v, i) => {
                                                                return (

                                                                    <li key={i}>
                                                                        <div className="collapsible-header boldText br-8">{v.titulo}</div>
                                                                        <div className="collapsible-body ft-16 vlmx-navy-80 fw-300">{getText(v.descripcion)}</div>
                                                                    </li>

                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                    : null
                                            }
                                            {
                                                selectOpt && selectOpt === 'PRODUCTOS' ?

                                                    <div className='row paddingLeftBotones'>
                                                        <div className='col s12 lef-align mt-1'>
                                                            <div className='row botonesProductos'>

                                                        {
                                                            Object.keys(faqs[selectOpt]).map((v, i) => {
                                                                return (
                                                                    <div className='col col s12 m12 l4 xl4 center-align margenBotonesProd' key={i}>
                                                                        <button className={`btn ${getClassBtn(v, btnS)} ft-14 boldText br-12 txt_transformNone`}
                                                                        onClick={()=>{
                                                                            setContent(faqs[selectOpt][v])
                                                                            setBtnS(v)
                                                                        }}
                                                                        > {toTitleCase(v)} </button>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        </div>
                                                        </div>
                                                        <div className='col s12 paddingLeft0'>
                                                            <ul className="collapsible noBoxShadow br-8 white" >
                                                                {
                                                                    content && content.map((v, i) => {
                                                                        return (

                                                                            <li key={i}>
                                                                                <div className="collapsible-header boldText br-8">{v.titulo}</div>
                                                                                <div className="collapsible-body ft-16 vlmx-navy-80 fw-300">
                                                                                   {

                                                                                        getText(v.descripcion)
                                                                                     
                                                                                   }
                                                                                    </div>
                                                                            </li>

                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>

                                                    </div>

                                                    : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null}
            </div>
        </div>
    )

}

export default Faqs;