import { useState } from "react";
import AvisoDePrivacidad from "./aviso";
import Terminos from "./terminos";
import { useDispatch, useSelector } from "react-redux";
import { setUser, toogleLoad } from "../redux/slices/appslice";
import { aceptaTerminosYCondiciones } from "../ws";
import M from 'materialize-css';

function ModalTC() {

    const dispatch = useDispatch();
    const appData = useSelector((state) => state.appData) 
    const [optSelected, setOpt] = useState(1);
    const [cargando, setCargando] = useState(false);

    async function aceptarTerminos(){
        try {
          
            setCargando(true);
            dispatch(toogleLoad(true))
            let resultAceptaTC = await aceptaTerminosYCondiciones(appData);
            
            if(resultAceptaTC){
              M.Modal.getInstance(document.getElementById('modalTC')).close();
              if (appData.user.UsuarioPV) {
                M.Modal.init(document.getElementById('modalPV'), {
                    dismissible: false
                });
                M.Modal.getInstance(document.getElementById('modalPV')).open();
            }
            }else{
                M.toast({html:'No se logro actualizar la información intente mas tarde.',classes:'red accent-4 white-text txtBold' })
            }

            setCargando(false);
            dispatch(toogleLoad(false))
        } catch (error) {
            setCargando(false);
            dispatch(toogleLoad(false))
        }
    }

    return (
        <div id="modalTC" className="modal modalTC modal-fixed-footer">
            <div className="modal-content modalTCContent">
                <div className="row">
                    <div className="col s12">
                        <div className="row mb-0" style={{ position: 'fixed', width: '100%', background: '#FFFFFF' }}>
                            <div className="col s12 left-align mt-1" style={{ paddingLeft: '2rem' }}>
                                <span className="ft-18 txt_main-dark-blue txtBold">Legales</span>
                            </div>
                            <div className="col s12 mt-1 card_fondo">
                                <div className="divider" />
                            </div>
                        </div>
                    </div>

                    <div className="col s12">
                        <div className="row" style={{ background: '#F6F8FA', marginTop: '4rem' }}>
                            <div className="col s4 left-align" style={{ paddingLeft: '2rem' }}>
                                <div style={{ position: 'fixed', width: 'inherit' }}>
                                    <p className="cursorPointer ft-14 txt_main-dark-blue txtBold"
                                        onClick={() => {
                                            setOpt(1)
                                        }}
                                    >1. Términos y Condiciones</p>
                                    <p className="cursorPointer ft-14 txt_main-dark-blue txtBold"
                                        onClick={() => {
                                            setOpt(2)
                                        }}
                                    >2. Aviso de Privacidad</p>
                                </div>
                            </div>
                            <div className="col s8" style={{height:'90vh'}}>
                                <div className="row" style={{ background: '#FFFFFF' }}>
                                    <div className="col s12 mt-1">
                                        {
                                            optSelected === 1 ?
                                                <Terminos />

                                                : <AvisoDePrivacidad />
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="modal-footer">
                <div className="row">
                    <div className="col s12 m6 center-align" style={{paddingTop: '10px'}}>
                        <span style={{ color: '#33445F' }} className="ft-14">He leído y acepto los “Términos y Condiciones” y “Aviso de Privacidad”</span>
                    </div>
                    <div className="col s12 m6  center-align">
                                <button className="btn btnRechazarTC"
                                onClick={()=>{
                                    M.Modal.getInstance(document.getElementById('modalTC')).close();
                                    
                                    dispatch(setUser(false));   
                                }}
                                disabled={cargando}
                                > Rechazar </button>
                                <button className="btn btnContinuarTC waves-effect" 
                                disabled={cargando}
                                
                                style={{marginLeft:'2rem'}}
                                onClick={()=>{
                                    aceptarTerminos();
                                }}
                                > Aceptar </button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ModalTC;