import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { nWithCommas } from "../utils";


const COLORS = [
    'rgba(164, 158, 139, 1)',
    'rgba(203, 225, 255, 1)',
    'rgba(189, 239, 247, 1)',
    'rgba(108, 219, 199, 1)',
    'rgba(166, 208, 67, 1)',
    'rgba(203, 200, 197, 1)',
    'rgba(158, 200, 255, 1)',
    'rgba(18, 195, 226, 1)',
    'rgba(188, 238, 226, 1)',
    'rgba(213, 233, 172, 1)',
]


function PortafolioInversion() {

    const appData = useSelector((state) => state.appData)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams();

    const [dataPage, setDataPage] = useState({
        titulo: '',
        to_return: '/',
        fechaUltActividad: '',
        saldos: false
    });

    const [imageChart, setImageChart] = useState(false);


    function getDataPage({ titulo, to_return }) {
        let fechaUltActividad = '';
        if (appData.saldosFecha) {

            for (let saldo of appData.saldosFecha) {
                if (saldo.Producto && saldo.Producto.length) {
                    if (saldo.Producto[0].TipoCliente.toUpperCase() === titulo.toUpperCase()) {
                        fechaUltActividad = saldo.FechaUltActividad
                    }
                }
            }
        }

        if (appData.saldosTrimestrales) {
            if (appData.saldosTrimestrales && appData.saldosTrimestrales.saldosAgrupadosXPlan && appData.saldosTrimestrales.saldosAgrupadosXPlan.length) {
                for (let saldos of appData.saldosTrimestrales.saldosAgrupadosXPlan) {
                    if (saldos.Producto && saldos.Producto.TipoCliente.toUpperCase() === titulo.toUpperCase()) {


                        let total_rendimientos = saldos.saldos.reduce((total, elemento) => total + elemento.rendimiento, 0);
                        let total_saldo = saldos.saldos.reduce((total, elemento) => total + elemento.saldo, 0);

                        setDataPage({
                            ...dataPage,
                            titulo,
                            to_return,
                            fechaUltActividad: fechaUltActividad,
                            saldos: saldos.saldos,
                            dataGraph: makeDataGraph(saldos.saldos),
                            total_rendimientos: total_rendimientos,
                            total_saldo: total_saldo
                        })
                    }
                }
            }
        }
    }

    function makeDataGraph(saldos) {
   
        let objData = {
            // labels: saldos.map((saldo)=>{
            //     return `${saldo.plan}\n$${nWithCommas(parseFloat(saldo.saldo).toFixed(2))}\t\n${parseFloat(saldo.porcentaje).toFixed(2)}%`
            // }),
            labels: [],
            datasets: [
                {
                    label:[],
                    data: [],
                    backgroundColor: COLORS,
                    borderColor: [
                        'rgba(164, 158, 139, 1)',
                        'rgba(203, 225, 255, 1)',
                        'rgba(189, 239, 247, 1)',
                        'rgba(108, 219, 199, 1)',
                        'rgba(166, 208, 67, 1)',
                        'rgba(203, 200, 197, 1)',
                        'rgba(158, 200, 255, 1)',
                        'rgba(18, 195, 226, 1)',
                        'rgba(188, 238, 226, 1)',
                        'rgba(213, 233, 172, 1)'
                    ],
                    borderWidth: 1,
                },
            ],
        }

        for (let saldo of saldos) {
            objData.datasets[0].data.push(parseFloat(saldo.saldo))
        }

        return objData
    }

    useEffect(() => {



        let grafica_new = false;

        if (dataPage.dataGraph) {

            //getNewChart(dataPage.dataGraph);

            const ctx_new = document.getElementById('grafica_nueva');
            window.Chart.defaults.global.showTooltips = false;
           
            grafica_new = new window.Chart(ctx_new, {
                type: 'outlabeledDoughnut',
                data: dataPage.dataGraph,
                options: {
                    rotation: dataPage.saldos.length === 1 ? 1.5 : 0,
                    cutoutPercentage: 60,
                    layout: {
                        padding: {
                            top: dataPage.saldos.length === 1 ? 0 : 10
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: true,
                    plugins: {
                        outlabels: {
                            stretch: 40,
                            backgroundColor: '#fefefe00',
                            textAlign: 'right',

                            text: (val) => {
                                let valor = val.dataset.data[val.dataIndex];
                                if (parseFloat(valor).toFixed(2) > 0.0) {

                                    const saldoName = dataPage.saldos.filter((v, i) => v.saldo === valor);
                                    const total_saldo = dataPage.saldos.reduce((total, elemento) => total + elemento.saldo, 0);
                                    const porcentaje = (valor * 100) / total_saldo;
                                    return `${saldoName[0].plan}\n$${nWithCommas(parseFloat(valor).toFixed(2))}\t\n${parseFloat(porcentaje).toFixed(2)}%\t`
                                } else {
                                    return ''
                                }

                            },
                            color: 'black',
                            font: {
                                resizable: true,
                                minSize: 12,
                                maxSize: 18
                            },
                        },
                    },
                    tooltips: {
                        enabled: false // <-- this option disables tooltips
                    }
                }
            });

        }

        return () => {

            if (grafica_new) {
                grafica_new.destroy();
            }

        }
    }, [dataPage])

    useEffect(() => {
        if (params.tipo && params.tipo === 'fondodeahorro') {
            getDataPage({ titulo: 'Fondo de Ahorro', to_return: '/fondodeahorro' })
        } else if (params.tipo && params.tipo === 'cajadeahorro') {
            getDataPage({ titulo: 'Caja de Ahorro', to_return: '/cajadeahorro' })
        } else if (params.tipo && params.tipo === 'plandepensiones') {
            getDataPage({ titulo: 'Fondo de Pensiones', to_return: '/plandepensiones' })
        } else {
            navigate('/')
        }
    }, [params])

    return (
        <div className="container" style={{ minHeight: '100vh' }}>
            <div className="row mt-2">
                <div className="col s12 left-align">
                    <p className='cursorPointer'
                        onClick={() => {
                            navigate(dataPage.to_return)
                        }}
                    ><i className='material-icons left'>keyboard_backspace</i> {dataPage.titulo}</p>
                    <h5 className="txtBold">Portafolio de Inversión</h5>
                </div>

                <div className="col s12 ">
                    <div className="row">
                    <div className="col s12">
                        {/* <div className="col s12 m12 l6 xl6"> */}
                            <div className="col s12 right-align mb-1">
                                <span>Información al {dataPage.fechaUltActividad.split(' ').length > 1 ? dataPage.fechaUltActividad.split(' ')[0] : dataPage.fechaUltActividad}</span>
                            </div>
                            <div className="row mb-0">
                                <div className="col s6 left-align txtBold">
                                    Fondos:
                                </div>
                                <div className="col s6 right-align txtBold">
                                    Saldo
                                </div>
                                <div className="col s12 mt-1">
                                    <div className="divider" />
                                </div>
                            </div>
                            <div className="col s12 mt-1">

                            </div>
                            {
                                dataPage.saldos && dataPage.saldos.length ?
                                    dataPage.saldos.filter((f) => parseFloat(f.saldo) >= 1).map((v, i) => {
                                        return (
                                            <div className="row" key={i}>
                                                <div className="col s6 left-align">
                                                   <span className="colorFondo" style={{backgroundColor:COLORS[i], color:COLORS[i]}}>aa</span> {v.plan}
                                                </div>
                                                <div className="col s6 right-align">
                                                    $ {nWithCommas(parseFloat(v.saldo).toFixed(2))}
                                                </div>
                                                {
                                                    i < dataPage.saldos.length - 1 ?
                                                        <div className="col s12">
                                                            <div className="divider" />
                                                        </div> : null
                                                }

                                            </div>
                                        )
                                    })

                                    : null
                            }

                            {
                                dataPage.total_rendimientos ?
                                    <div className="row totalPI">
                                        <div className="col s6 left-align txtBold">
                                            Rendimiento:
                                        </div>
                                        <div className="col s6 right-align ft-16 txtBold">
                                            $ {nWithCommas(parseFloat(dataPage.total_rendimientos).toFixed(2))}
                                        </div>

                                    </div>
                                    : null
                            }
                            {
                                dataPage.total_saldo ?
                                    <div className="row totalPI">
                                        <div className="col s6 left-align txtBold">
                                            Saldo Total :
                                        </div>
                                        <div className="col s6 right-align ft-16 txtBold">
                                            $ {nWithCommas(parseFloat(dataPage.total_saldo).toFixed(2))}
                                        </div>

                                    </div>
                                    : null
                            }
                        </div>
                        {/* <div className="col s12  chartContainer mt-1"
                        >

                            {
                                dataPage.dataGraph && imageChart ?

                                <img src={imageChart}  alt='grafica'/>


                                    : null
                            }

                        </div> */}
                        {/* <div className="col s12 m12 l6  chartContainer"
                        > */}
                        <div className="col s12  chartContainer"
                        >

                            {
                                dataPage.dataGraph  ?

                                <canvas id="grafica_nueva" height={300}></canvas>


                                    : null
                            }

                        </div>


                    </div>
                </div>


            </div>

        </div>
    )

}


export default PortafolioInversion;