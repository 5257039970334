import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {showModalFinSesion, setUser } from "../redux/slices/appslice";
import M from 'materialize-css';


function ModalFinSesion() {
    const finSesionModal = useSelector((state) => state.appData.finSesionModal);
    const dispatch = useDispatch();
    const [instanceModal, setInstanceModal] = useState(false)
    useEffect(() => {
        if (instanceModal) {
            if (finSesionModal) {
                instanceModal.open();
            } else {
                instanceModal.close()
            }
        }
    }, [finSesionModal])

    useEffect(() => {
     
    
            M.Modal.init(document.getElementById('modalFinSesion'), { 
                dismissible: false,
                opacity: 1,
                endingTop:'30%' });
            setInstanceModal(M.Modal.getInstance(document.getElementById('modalFinSesion')));
        
       
    }, [])

    return (
        <div id="modalFinSesion" className="modal">
            <div className="modal-content">
               <div className="row">
                <div className="col s12 center-align">
                    <p className="txtBold ft-16">ATENCIÓN</p>
                    <p className="ft-14">{finSesionModal.texto}</p>

                    <div className="row">
                        <div className="col s12 ">
                            <div className="divider"/>
                        </div>
                        <div className="col s12 mt-1">
                         <button type="button" className="btn btn-webSi-primary waves-effect light"
                         onClick={()=>{
                            dispatch(showModalFinSesion(false));
                            dispatch(setUser(false))

                            if(finSesionModal.action){
                                finSesionModal.action();
                            }
                         }}
                         >Entendido</button>
                        </div>
                    </div>
                </div>
               </div>
               
            </div>
        </div>
    )

}

export default ModalFinSesion;