import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router"
import { ICONS } from "../assets/icons";
import { useSelector, useDispatch } from "react-redux";
import M from 'materialize-css';
import { NumericFormat } from 'react-number-format';
import { nWithCommas, convertirCamelCase, TEXT_FIN_SESION_JWT } from "../utils";
import {
    showModalFinSesion,
    toogleLoad, setSaldosPrestamos, setSaldosMovimientos
} from "../redux/slices/appslice";
import { solicitarPrestamo, calcularPrestamo } from "../ws";

function SolicitudPrestamo() {
    const dispatch = useDispatch()
    const appData = useSelector((state) => state.appData)
    const TEXTO_PASOS = {
        1: 'Selecciona tu Préstamo',
        2: 'Completa el formulario',
        3: 'Confirmación',
        4: 'Confirmación de envío'
    }

    const navigate = useNavigate()
    const params = useParams();

    const [dataPage, setDataPage] = useState({
        titulo: '',
        to_return: ''
    });



    const [paso, setPaso] = useState(1);
    const [monto, setMonto] = useState()
    const [errorMsg, setErrorMsg] = useState(false)

    const [tipoPrestamo, setTipoPrestamo] = useState(false);
    const [tipoPago, setTipoPago] = useState(false);
    const [msgExito, setMsgExito] = useState(false);

    useEffect(() => {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems, {});
    }, [])

    useEffect(() => {



        if (appData.configPrestamo && appData.configPrestamo.TipoPago && appData.configPrestamo.TipoPago.length) {

            if (appData.configPrestamo.TipoPago && appData.configPrestamo.TipoPago.length) {
                setTipoPago(appData.configPrestamo.TipoPago[0].IdTipoPago);
            }
        }

    }, [appData])



    useEffect(() => {
        if (params.tipo && params.tipo === 'fondodeahorro') {

            setDataPage({ titulo: 'Fondo de Ahorro', to_return: '/prestamos/fondodeahorro' });

        } else if (params.tipo && params.tipo === 'cajadeahorro') {

            setDataPage({ titulo: 'Caja de Ahorro', to_return: '/prestamos/cajadeahorro' });

        } else {
            navigate('/')
        }

    }, [params])

    function getTextButton() {
        if (paso < 3) {
            return 'Continuar'
        }

        if (paso === 3) {
            return 'Enviar Solicitud'
        }

        if (paso === 4) {
            return 'Salir'
        }
    }


    const solicitaPrestamo = async () => {
        dispatch(toogleLoad(true));
        try {

            
            let calcularPrestamoResult = await calcularPrestamo(appData, { tipoPrestamo, tipoPago, monto }, () => {
                dispatch(showModalFinSesion({ texto: TEXT_FIN_SESION_JWT }))
            });

            if (calcularPrestamoResult.BanderaExito) {
                let resultSolicitudPrestamo = await solicitarPrestamo(appData, { tipoPrestamo, tipoPago, monto }, () => {
                    dispatch(showModalFinSesion({ texto: TEXT_FIN_SESION_JWT }))
                });
                if (resultSolicitudPrestamo) {

                    if (resultSolicitudPrestamo.BanderaExito && resultSolicitudPrestamo.Datos.Mensaje.trim() !== "") {
                        dispatch(setSaldosPrestamos(false));
                        dispatch(setSaldosMovimientos(false));
                        setMsgExito(resultSolicitudPrestamo.Datos.Mensaje.trim())
                        setPaso(4);
                    }

                }
            }


        } catch (error) {

        }

        dispatch(toogleLoad(false));
    }

    return (
        <div className="container mt-2" style={{ minHeight: '90vh' }}>
            <div className="row">

                <div className="col s4 left-align">
                    <p className='cursorPointer'
                        onClick={() => {
                            navigate(dataPage.to_return)
                        }}
                    ><i className='material-icons left'>keyboard_backspace</i> <span className="ft-16 txtBold">Solicitar Préstamo </span></p>
                </div>

                <div className="col s4 center-align ft-14" style={{ paddingTop: '17px' }}>
                    {paso}. {TEXTO_PASOS[paso]}
                </div>

                <div className="col s4 right-align" style={{ paddingTop: '10px' }}>
                    <a className="waves-effect waves-light btn ft-14 txt_main-dark-blue white br-12 btnCancel txtBold"
                        onClick={() => {
                            navigate(dataPage.to_return)
                        }}
                    ><i className="material-icons left ft-14 txt_main-dark-blue">clear</i>Cancelar</a>
                </div>


                <div className="col s4 center-align mt-1">
                    <div className="row">
                        <div className="col s3">
                            <img src={ICONS.elipseLleno} alt="paso" />
                        </div>
                        <div className="col s3">
                            <img src={paso >= 2 ? ICONS.elipseLleno : ICONS.elipseVacio} alt="paso" />
                        </div>
                        <div className="col s3">
                            <img src={paso >= 3 ? ICONS.elipseLleno : ICONS.elipseVacio} alt="paso" />
                        </div>
                        <div className="col s3">
                            <img src={paso >= 4 ? ICONS.elipseLleno : ICONS.elipseVacio} alt="paso" />
                        </div>
                    </div>
                </div>
                <div className="col s4"></div>

                <div className="col s12 mt-1 ">
                    <div className="row">
                        {
                            paso === 4 ?
                                <div className="col s12">
                                    <div className="row center-align">
                                        <div className="col s12 mt-4">
                                            <img src={ICONS.solicitudAprobada} alt="solicitud aprobada" />
                                        </div>
                                        <div className="col s12 ft-24 mt-2 colorDarkBlue">
                                            {
                                                msgExito ? msgExito : 'La solicitud de tu Préstamo ha sido enviada'
                                            }

                                        </div>
                                        <div className="col s12 ft-16 ">

                                            <p className="mb-0">Préstamo sujeto a autorización</p>
                                            <p className="mt-0">Puedes revisar el estatus de tu solicitud en</p>

                                            <p className="linkCustom"
                                                onClick={() => {

                                                    if (appData.configPrestamo && appData.configPrestamo.productoConfig) {
                                                      
                                                        let tipoCli = appData.configPrestamo.productoConfig.TipoCliente.toUpperCase();
                                                        if (tipoCli === 'FONDO DE AHORRO') {
                                                            navigate('/prestamos/fondodeahorro');
                                                        } else if (tipoCli === 'CAJA DE AHORRO') {
                                                            navigate('/prestamos/cajadeahorro');
                                                        } else {
                                                            navigate('/');
                                                        }


                                                    }


                                                }}
                                            >Mis Préstamos</p>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                        {
                            paso === 3 ?
                                <div className="col s12">
                                    <div className="row center-align">
                                        <div className="col s12 labelTxt ft-16">
                                            Tipo de Préstamo
                                        </div>
                                        <div className="col s12 ft-24 txtBold">
                                            {tipoPrestamo ? appData.configPrestamo.TiposPrestamos.filter((v, i) => v.IdTipoPrestamo === parseInt(tipoPrestamo))[0].Descripcion : ''}
                                        </div>

                                        <div className="col s12 labelTxt ft-16 mt-1">
                                            Tipo de Pago
                                        </div>
                                        <div className="col s12 ft-24 txtBold">
                                            {tipoPago ? convertirCamelCase(appData.configPrestamo.TipoPago.filter((v, i) => v.IdTipoPago === parseInt(tipoPago))[0].Descripcion) : ''}
                                        </div>

                                        <div className="col s12 labelTxt ft-16 mt-1">
                                            Plazos
                                        </div>
                                        <div className="col s12 ft-24 txtBold">
                                            12
                                        </div>

                                        <div className="col s12 labelTxt ft-16 mt-1">
                                            Monto
                                        </div>
                                        <div className="col s12 ft-24 txtBold">
                                            $ {nWithCommas(parseFloat(monto).toFixed(2))}
                                        </div>

                                        <div className="col s12 labelTxt ft-16 mt-1">
                                            Depósito del Préstamo
                                        </div>
                                        <div className="col s12 ft-24 txtBold">
                                            Cuenta de Cheques
                                        </div>
                                    </div>
                                </div>
                                : null
                        }

                        <div className="col s12 lef-align labelTxt" hidden={paso >= 3}>
                            Tipo de Préstamo
                        </div>
                        <div className="input-field col s12" hidden={paso >= 3}>
                            <select value={tipoPrestamo} onChange={(e) => {
                                setTipoPrestamo(e.target.value)
                            }}>
                                <option value="">Selecciona una opción</option>
                                {appData.configPrestamo && appData.configPrestamo.TiposPrestamos ?
                                    appData.configPrestamo.TiposPrestamos.map((v, i) => {
                                        return <option key={i} value={v.IdTipoPrestamo}
                                        >{v.Descripcion}</option>
                                    })

                                    : <option></option>}
                            </select>

                        </div>
                        <div className="col s12 mt-1 labelTxt" hidden={paso < 2 || paso >= 3}>
                            Importe
                        </div>
                        <div className="input-field col s12" hidden={paso < 2 || paso >= 3}>
                            <NumericFormat
                                id="monto"
                                thousandSeparator={','}
                                decimalSeparator="."
                                value={monto}
                                isNumericString
                                decimalScale={2}
                                fixedDecimalScale
                                prefix="$"
                                placeholder="$0.00"
                                onValueChange={(values) => {
                                 
                              
                                    let errorMsg = false;

                                    if (parseFloat(values.value) <= 0) {
                                        errorMsg = "Indique un monto válido mayor a $0.00.";
                                    }



                                    setErrorMsg(errorMsg);
                                    setMonto(values.value);

                                }}
                            />
                            {errorMsg ?
                                <span className="helper-text red-text text-darken-3" >{errorMsg}</span>
                                : null}
                        </div>
                    </div>

                </div>
                {
                    paso > 1 && paso < 4 ?
                        <div className="col s12 center-align">
                            <button className="backButton"
                                onClick={() => {
                                    let currentPaso = paso;
                                    if (paso > 1 && paso < 4) {
                                        currentPaso = currentPaso - 1;
                                        setPaso(currentPaso);
                                    }
                                }}>Atrás</button>
                        </div>

                        : null
                }
                <div className="col s12 center-align mt-1">
                    <button className="btnContinue"
                        onClick={() => {

                            let currentPaso = paso;

                            if (paso === 1 && tipoPrestamo && tipoPrestamo !== "") {
                                currentPaso = currentPaso + 1;
                            } else if (paso === 1 && !tipoPrestamo || tipoPrestamo === "") {

                                M.toast({ html: "<i class='material-icons left'>warning</i>Seleccione un tipo de préstamo.", classes: 'yellow accent-4 black-text txtBold' });
                            }

                            if (paso === 2 && parseFloat(monto) <= 0 || monto === "") {
                                M.toast({ html: "<i class='material-icons left'>warning</i>Ingrese un importe válido.", classes: 'yellow accent-4 black-text txtBold' });
                            } else if (paso === 2 && parseFloat(monto) > 0) {
                                currentPaso = currentPaso + 1;
                            }


                            if (paso === 3) {
                                solicitaPrestamo();
                            }

                            if (paso === 4) {

                                if (appData.configPrestamo && appData.configPrestamo.Producto.length) {
                                    let tipoCli = appData.configPrestamo.productoConfig.TipoCliente.toUpperCase();
                                    if (tipoCli === 'FONDO DE AHORRO') {
                                        navigate('/fondodeahorro');
                                    } else if (tipoCli === 'CAJA DE AHORRO') {
                                        navigate('/cajadeahorro');
                                    } else {
                                        navigate('/');
                                    }

                                }
                            } else {
                                setPaso(currentPaso)
                            }

                        }}
                    >{
                            getTextButton()
                        }</button>
                </div>
            </div>
        </div>
    )
}

export default SolicitudPrestamo;