import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { nWithCommas } from "../utils";

Chart.register(ArcElement);

const HalfDonut = ({ prestamoSelected }) => {

  const [data, setData] = useState({
    datasets: [
      {
        data: [0, 10],
        backgroundColor: [
          "#41BBC9",
          "#DEEFF1"
        ],
        display: true,
        borderColor: "#FFFF"
      }
    ]
  })


  useEffect(() => {

    if (prestamoSelected) {

      let data = [0, 10];

      if (prestamoSelected.Estatus === 'Aplicado') {
        data = [prestamoSelected.PlazosLiquidados, (prestamoSelected.Plazos - prestamoSelected.PlazosLiquidados)]
      }

      setData({
        datasets: [
          {
            data,
            backgroundColor: [
              "#41BBC9",
              "#DEEFF1"
            ],
            display: true,
            borderColor: "#FFFF"
          }
        ]
      })
    }



  }, [])


  return (
    <div>
      <Doughnut
        id="halfDonut"
        data={data}
        options={{
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              enabled: false
            }
          },
          rotation: -90,
          circumference: 180,
          cutout: "80%",
          maintainAspectRatio: true,
          responsive: true
        }}
      />
      <div
        style={{
          position: "absolute",
          top: "25%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center"
        }}
      >
        <div>
          <p className="mb-0">Monto Solicitado</p>
          <p className="ft-24 txtBold mt-0">${nWithCommas(parseFloat(prestamoSelected.Monto).toFixed(2))}</p>
        </div>
      </div>
    </div>
  );
};

export default HalfDonut;
