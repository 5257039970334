
import { useSelector } from 'react-redux';

function AvisoDePrivacidad({centerTitle}) {
    const appData = useSelector((state) => state.appData)

    if (appData.legales && appData.legales.Aviso) {

        return (
            <div className="row contentLegal">
                <div className={`col s12 left-align ft-18 labelNavy txtBold ${centerTitle ? 'center-align':''}`}>
                    { decodeURIComponent(escape(atob( appData.legales.Aviso.titulo )))}
                </div>
                <div className="col s12 left-align ft-16 fw-400 mt-1 wrapDataLegales">
                { decodeURIComponent(escape(atob( appData.legales.Aviso.contenido )))}
                </div>
            </div>
        )

    }
    return (<div></div>)
}


export default AvisoDePrivacidad;