import React, {useState, useEffect} from "react";
import Switch from "react-switch";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { setMostrarSaldo } from "../firestore";
import { setMuestraSaldoF } from "../redux/slices/appslice";

function Security() {
    const appData = useSelector((state) => state.appData)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [muestraSaldo, setMuestraSaldo ] = useState(false)

    useEffect(()=>{
      
        setMuestraSaldo(appData.muestraSaldo);
    },[]);


    async function setMSaldoF(muestraSaldo){
        try {
         
            await setMostrarSaldo(muestraSaldo,appData.user.IdUsuario)
            dispatch(setMuestraSaldoF(muestraSaldo))
            setMuestraSaldo(muestraSaldo);
        } catch (error) {
            
        }
    }


    return (
        <div className="row ml-10">
            <div className="col s12 left-align ft-18 txtBold">
                Seguridad
            </div>
            <div className='col s12'>
                <div className='row'>
                    <ul className="collection colSecurity ft-14 fw-400 txt_main-dark-blue">
                        <li className="collection-item colIS cursorPointer" onClick={()=>{
                            navigate('/perfil/contrasenia');
                        }}>
                            <div>Cambiar Contraseña<Link to='/perfil/contrasenia' className="secondary-content"><i className="material-icons txt_main-dark-blue">chevron_right</i></Link></div>
                        </li>
                        <li className="collection-item colIS">
                            <div>Mostrar Saldo<a href="#!" className="secondary-content"><Switch onChange={async (checked)=>{
                              
                                await setMSaldoF(checked)
                            }} checked={muestraSaldo} 
                            height={24} 
                            width={50} 
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor={'#64b5f6'}
                            /></a></div>
                        </li>
                        <li className="collection-item colIS cursorPointer"
                        
                        onClick={()=>{
                            navigate('/perfil/bitacora');
                        }}>
                            <div>Bitácora de Accesos<Link to='/perfil/bitacora' className="secondary-content"><i className="material-icons txt_main-dark-blue">chevron_right</i></Link></div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )

}

export default Security