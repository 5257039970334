import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Products from '../components/products';

function Conocenos() {
    const appData = useSelector((state) => state.appData)
    const [content, setContent] = useState({ titulo: "", descripcion: "" })
    const [contentProduct, setContentProduct] = useState(false)
    const [btnS, setBtnS] = useState('')
    const navigate = useNavigate();


    useEffect(() => {
        document.body.classList.add('bodyUser')

        return () => {
            document.body.classList.remove('bodyUser')
            document.body.classList.add('bodyLogin')
        }
    }, [])

    useEffect(() => {
    
        if (content.titulo.toUpperCase() === 'PRODUCTOS') {
            if (appData.productosInfo && Object.keys(appData.productosInfo).length) {
                let obj = appData.productosInfo[Object.keys(appData.productosInfo)[0]]
                setBtnS(obj.Titulo);
                setContentProduct(obj);
            }
        }
    }, [content])

    useEffect(() => {
        if (appData.preguntas && appData.preguntas.conocenos) {
            setContent(appData.preguntas.conocenos[0])
        }
    }, [appData.preguntas])

    return (
        <div className="container">
            <div className="row">
                <div className="col s12 left-align mt-2">
                    <p className='cursorPointer'
                        onClick={() => {
                            navigate('/login')
                        }}
                    ><i className='material-icons left'>keyboard_backspace</i> Log in</p>
                    <h5 className="txtBold">Conócenos</h5>
                </div>
                {
                    appData.preguntas ?

                        <div className="col s12 mt-2">
                            <div className="row ">
                                <div className="col s3">
                                    <div className="row btnSide">

                                        {
                                            appData.preguntas.conocenos ?
                                                appData.preguntas.conocenos.map((v, i) => v.habilitado ? <div className="col s12" key={i}>
                                                    <button className={`btn waves-effect light btnAct white ${content.titulo === v.titulo ? 'optSelected' : ''} ft-14`}
                                                        key={i}
                                                        onClick={() => {
                                                            setContent(v)
                                                        }}
                                                    >{v.titulo}</button>
                                                </div> : null)
                                                : null
                                        }
                                    </div>
                                </div>
                                <div className="col s9">
                                    <div className="row">
                                        {
                                            content.titulo !== 'Productos' ?
                                                <div className="col s12 left-align wrapDataProfile brT brB ln1_5">
                                                    <p className='boldText'>{content.titulo}</p>
                                                    <p className="ft-14 fw-300 labelNavy">{content.descripcion}</p>
                                                </div>
                                                :
                                                <Products />

                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                        : null}
            </div>
        </div>
    )

}

export default Conocenos;