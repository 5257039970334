import './App.css';
import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import { Provider } from 'react-redux';
import store from './redux/store';
import ModalLoading from './components/modalLoading';
import ModalFinSesion from './components/modalFinSesion';


function App() {
  return (
    <Provider store={store}>
      <RouterProvider router={router} />
      <ModalLoading />
      <ModalFinSesion />
    </Provider>
  );
}

export default App;
