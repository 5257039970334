
import { useEffect } from "react";

function MapBitacora({ lat, long, idMap }) {


    useEffect(() => {
     

        try {
            var mymap = window.L.map(`mapID_${idMap}`).setView([lat, long], 17);
            window.L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '',
                maxZoom: 18,
                tileSize: 512,
                zoomOffset: -1
            }).addTo(mymap);

            window.L.marker([lat, long]).addTo(mymap);
        } catch (error) {
           
        }

    }, [])


    return (
        <div className="row">
            <div className="col s12">
                <div id={`mapID_${idMap}`} style={{height:'300px'}}></div>
            </div>
        </div>
    )


}

export default MapBitacora;