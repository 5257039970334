
import CardBalance from "../components/cardBalance";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { nWithCommas, TEXT_FIN_SESION_JWT} from "../utils";
import {
    toogleLoad, setSaldosFecha, setSaldosMovimientos, setSaldosTrimestrales,
    setTokenSesion, setInfoFondos, showModalFinSesion,
} from "../redux/slices/appslice";
import {
    getSaldosFecha, getMovimientos, getSaldosTrimestrales,
    getInfoFondosWS
} from "../ws";
import {getKeyInfoFondo} from '../firestore';


function MisBalances() {
    const dispatch = useDispatch()
    const appData = useSelector((state) => state.appData)
    const [saldosFecha, setSaldosFechaClass] = useState([])

    useEffect(() => {
        dispatch(toogleLoad(true));
        getSF();
        getST();
        getMovi();
        getInfoFondos();
   
    }, [])


    function orderSaldos(sf) {
        let saldos = []
        for (let saldo of sf) {
            let copy = JSON.parse(JSON.stringify(saldo));
            if (saldo.Producto[0].TipoCliente === 'Fondo de Pensiones') {
                copy['order'] = 3
            }
            if (saldo.Producto[0].TipoCliente === 'Fondo de Ahorro') {
                copy['order'] = 1
            }

            if (saldo.Producto[0].TipoCliente === 'Caja de Ahorro') {
                copy['order'] = 2
            }

            saldos.push(copy)

        }

        saldos.sort((a, b) => a.order - b.order);

        return saldos;
    }

    const getInfoFondos = async () => {

        if (!appData.infoFondos) {
           
            let key = await getKeyInfoFondo();

            
            let result = await getInfoFondosWS(appData, key);
          
            dispatch(setInfoFondos(result));
        } 
    }

    const getSF = async () => {

        if (!appData.saldosFecha) {
            const saldoFecha = await getSaldosFecha(appData, ()=>{
                dispatch(showModalFinSesion({texto:TEXT_FIN_SESION_JWT}))
            });

            if (saldoFecha && saldoFecha.Datos && saldoFecha.Datos.length) {
                setSaldosFechaClass(orderSaldos(saldoFecha.Datos))
                dispatch(setSaldosFecha(saldoFecha.Datos));
            }

            if (saldoFecha && saldoFecha.JWT) {
                dispatch(setTokenSesion(saldoFecha.JWT.current_token))
            }
        } else {

            setSaldosFechaClass(orderSaldos(appData.saldosFecha));
        }

        dispatch(toogleLoad(false));
    }

    const getST = async () => {
        dispatch(toogleLoad(true));

        if (!appData.saldosTrimestrales) {

            const saldoTrimestral = await getSaldosTrimestrales(appData,()=>{
                dispatch(showModalFinSesion({texto:TEXT_FIN_SESION_JWT}))
            });
            if (saldoTrimestral && saldoTrimestral.Datos) {
                dispatch(setSaldosTrimestrales(saldoTrimestral.Datos));
            }

            if (saldoTrimestral && saldoTrimestral.JWT) {
                dispatch(setTokenSesion(saldoTrimestral.JWT.current_token))
            }


            dispatch(toogleLoad(false));
        } else {
            dispatch(toogleLoad(false));
        }

    }

    const getMovi = async () => {
        if (!appData.saldosMovimientos) {
            const datosMovimientos = await getMovimientos(appData,()=>{
                dispatch(showModalFinSesion({texto:TEXT_FIN_SESION_JWT}))
            });
            if (datosMovimientos && datosMovimientos.Datos) {
                dispatch(setSaldosMovimientos(datosMovimientos.Datos));
            }
            if (datosMovimientos && datosMovimientos.JWT) {
                dispatch(setTokenSesion(datosMovimientos.JWT.current_token))
            }
        } else {
            dispatch(setSaldosMovimientos(appData.saldosMovimientos));
        }

    }

  

    return (
        <div className="row">
            <div className="col s12 bannerContent">
                <div className="container">
                    <div className="row">
                        <div className="col s12 m7 l5  mt-3 wrapperBanner">
                            <div className="row bannerTxt">
                                <div className="col s12">
                                    <span className="ft-32 secondaty_cyan fw-600">¡Hola {appData.user.Nombre}!</span>
                                </div>
                                <div className="col s12 mt-1">
                                    <span className="ft-16">
                                        Recuerda estar al pendiente
                                        de los movimientos de tu cuenta
                                        y reportar cualquier
                                        movimiento extraño.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col s12 balancesContainer">
                <div className="container mt-3">
                    <div className="row">
                        <div className="col s12 left-align">
                            <h5 className="boldText">Mis Balances</h5>
                        </div>

                        {
                            saldosFecha.length ?
                                saldosFecha.map((v, i) => {

                                    return (
                                        <div className="col s12 l4 mt-1 cardContainer">
                                            <CardBalance
                                                titulo={v.Producto[0].TipoCliente}
                                                cantidad={`$${nWithCommas(parseFloat(v.TotalLiquidar).toFixed(2))}`}
                                                derechoAdquirido={`$${nWithCommas(parseFloat(v.DerechoAdquirido).toFixed(2))}`}
                                                aportaciones={`$${nWithCommas(parseFloat(v.aportaciones).toFixed(2))}`}
                                                rendimiento={`$${nWithCommas(parseFloat(v.rendimiento).toFixed(2))}`}
                                                saldo={`$${nWithCommas(parseFloat(v.SaldoPrestamos).toFixed(2))}`}
                                                saldoTotal={`$${nWithCommas(parseFloat(v.SubTotal).toFixed(2))}`}
                                            />

                                        </div>
                                    )
                                })

                                : <div className="col s12 center-align">
                                    <h3>Cargando ...</h3>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


export default MisBalances;