import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { showModalFinSesion, setCoordenadas } from "../redux/slices/appslice";
import { getCoordenadas } from "../utils";
import { cerrarSesion } from '../ws';
import M from 'materialize-css';

const MESSAGE = 'Tu navegador no soporta la geolocalización es necesaria para poder continuar.';
const GeolocationPermissionListener = () => {

  const appData = useSelector((state) => state.appData)
  const dispatch = useDispatch();
  const [permissionStatus, setPermissionStatus] = useState('unknown');


  useEffect(() => {
   
    if (permissionStatus === 'denied') {
      dispatch(setCoordenadas(false));
      closeSesion();
    }

    if (permissionStatus === 'granted') {
      getLatLong();
    }
  }, [permissionStatus])

  async function closeSesion() {
    let instanceModal = M.Modal.getInstance(document.getElementById('modalFinSesion'));
    if (instanceModal) {
      if (appData.user) {
        await cerrarSesion(appData);
        dispatch(showModalFinSesion({ texto: MESSAGE }))
      } else {
        dispatch(showModalFinSesion({ texto: MESSAGE }))
      }
    }

  }

  const getLatLong = async () => {
    let coordenadas = await getCoordenadas();
    if (coordenadas) {
      dispatch(setCoordenadas(coordenadas))
    }
  }

  const handlePermissionChange = () => {
    navigator.permissions.query({ name: 'geolocation' })
      .then(permission => {
        setPermissionStatus(permission.state);
      })
      .catch(error => {
        console.error('Error al obtener el estado del permiso de geolocalización:', error);
      });
  };

  useEffect(() => {
    const checkPermissionStatus = async () => {
      try {
        const permission = await navigator.permissions.query({ name: 'geolocation' });
        setPermissionStatus(permission.state);

        window.addEventListener('change', handlePermissionChange);
      } catch (error) {
        console.error('Error al obtener el estado del permiso de geolocalización:', error);
      }
    };

    document.body.addEventListener('click', checkPermissionStatus);

    setTimeout(() => {
      checkPermissionStatus();
    }, 1000);
 

    return () => {
      window.removeEventListener('change', handlePermissionChange);
    };
  }, []);

};

export default GeolocationPermissionListener;
