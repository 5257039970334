
import React, { useState } from "react";
import { Outlet } from "react-router";
import { useNavigate } from "react-router";
import { useSelector } from 'react-redux';


function AcercaDe() {
    const appData = useSelector((state) => state.appData)
    const navigate = useNavigate()

    return (
        <div className="container">
            <div className="row">
                <div className="col s12 left-align mt-2">
                    <h5 className="txtBold">Acerca de Valmex</h5>
                </div>
                <div className="col s12 mt-2">
                    <div className="row ">
                        <div className="col s3">
                            <div className="row btnSide">
                                <div className="col s12">
                                    <button className="btn waves-effect light btnAct white"
                                        onClick={() => {
                                            navigate('productos')
                                        }}
                                    >Productos</button>
                                </div>
                                {
                                    appData && appData.infoFondos ?
                                        <div className="col s12">
                                            <button className="btn waves-effect light btnAct white"
                                                onClick={() => {
                                                    navigate('infoFondos')
                                                }}
                                            >Información de Fondos</button>
                                        </div>
                                        : null
                                }

                                <div className="col s12">
                                    <button className="btn waves-effect light btnAct white"
                                        onClick={() => {
                                            navigate('contacto')
                                        }}>Contacto</button>
                                </div>
                                <div className="col s12">
                                    <button className="btn waves-effect light btnAct white"
                                        onClick={() => {
                                            navigate('terminosycondicions')
                                        }}>Términos y Condiciones</button>
                                </div>
                                <div className="col s12">
                                    <button className="btn waves-effect light btnAct white"
                                        onClick={() => {
                                            navigate('avisodeprivacidad')
                                        }}>Aviso de Privacidad</button>
                                </div>
                            </div>
                        </div>
                        <div className="col s9">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )


}

export default AcercaDe;