import { Outlet } from 'react-router';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const ProtectedRoute = ({ element }) => {
  const appData = useSelector((state) => state.appData)

  let pathTO = '/login'

  if(appData.finSesionModal){
    if(appData.finSesionModal.tipo === 'FSI'){
      pathTO= '/login/fsi'
    }
  }

  return appData.user ? (
    element || <Outlet />
  ) : (
    <Navigate to={pathTO} replace />
  );
};