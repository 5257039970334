import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from '../assets/loader.gif';
import M from 'materialize-css';


function ModalLoading() {
    const loading = useSelector((state) => state.appData.loading);
    const [instanceModal, setInstanceModal] = useState(false)
    useEffect(() => {
        if (instanceModal) {
            if (loading) {
                instanceModal.open();
            } else {
                instanceModal.close()
            }
        }
    }, [loading])

    useEffect(() => {
        M.Modal.init(document.getElementById('loadingModal'), { 
            dismissible: false,
            endingTop:'30%' });
        setInstanceModal(M.Modal.getInstance(document.getElementById('loadingModal')));
    }, [])

    return (
        <div id="loadingModal" className="modal modal_loader">
            <div className="modal-content">
               <div className="row">
                <div className="col s12 center-align">
                    <p><img src={Loader} alt="Cargando" style={{maxWidth:"500px"}}/></p>
                </div>
               </div>
               
            </div>
        </div>
    )

}

export default ModalLoading;