import React from "react";
import { useState, useEffect } from "react";
import Logo from '../assets/logo.svg'
import M from 'materialize-css';
import { Link } from "react-router-dom";
import Token from "../components/token";
import FormNewPass from "../components/formNewPass";
import { useNavigate } from "react-router-dom";

function OlvidePass() {
    const [usuario, setUsuario] = useState("")
    const [step, setStep] = useState(0)
    const useNav = useNavigate();

    function submitLogin(e) {
        e.preventDefault()
    
        setStep(step + 1)
    }

    function showStep() {
        if (step === 0) {
            return (
                <form onSubmit={submitLogin}>
                    <div className="col s12 mt-4">
                        <span>¿Olvidaste tu contraseña?</span>
                    </div>

                    <div className="input-field col s12 mt-2">
                        <input id="user" type="text"
                            value={usuario}
                            onChange={(e) => {
                                setUsuario(e.target.value)
                            }}
                        />
                        <label htmlFor="user">Usuario</label>
                    </div>
                    <div className="col s12 mt-1">
                        <button type="submit"
                            disabled={usuario.trim().length === 0}
                            className="btn btn-webSi-primary waves-effect light">Continuar</button>
                    </div>
                </form>
            )
        } else if (step === 1) {
            return (
                <React.Fragment>
                    <div className="col s12 mt-2">
                        <span className="ft-20">Usuario:</span>
                    </div>
                    <div className="col s12">
                        <span className="ft-20">01234568971013P</span>
                    </div>

                    <div className="input-field col s12 mt-2">
                        <p className="ft-16 labelNavy">Se enviará un códido de verificación al siguiente correo:</p>
                        <p className="ft-16 primaryText">a******@g****.com</p>
                    </div>
                    <div className="col s12 mt-2">
                        <button type="button"
                            onClick={() => {
                                setStep(step + 1)
                            }}
                            className="btn btn-webSi-primary waves-effect light">Enviar Código</button>
                    </div>
                </React.Fragment>
            )
        } else if (step === 2) {
            return (
                <React.Fragment>
                    <div className="col s12 mt-2">
                        <span className="ft-20">Ingrese el código</span>
                    </div>

                    <div className="col s12 mt-2" style={{lineHeight:"7px"}}>
                        <p className="ft-16 labelNavy">Usuario: 01234568971013P</p>
                        <p className="ft-16 labelNavy">Revisa el correo que enviamos a</p>
                        <p className="ft-16 primaryText">a******@g****.com</p>
                    </div>
                    <div className="col s12 mt-2">
                        <Token callBack={() => {
                            setStep(step + 1)
                        }} />
                    </div>
                </React.Fragment>
            )
        }
    }

    const formReset = () => {
        return (
            <React.Fragment>
                <div className="col s12 m12 l2">
                    <img src={Logo} alt="logo" className="logoForgot"/>
                </div>
                <div className="col s12 m12 l10 center-align boldText pt-1">
                    <span className="ft-20">Reestablece tu contraseña</span>
                </div>
                <div className="col s12 m12 l10 mt-2 offset-l2">
                    <div className="row">
                        <div className="col s12">
                            <span className="ft-16 labelNavy">Esta será tu nueva contraseña para futuras sesiones</span>
                        </div>
                    </div>
                </div>
                <div className="col s12 m12 l10 offset-l2">
                    <FormNewPass />
                </div>
            </React.Fragment>
        )
    }


    return (
        <React.Fragment>
            <div className="loginModule" style={{ height: "100%" }}>
                <div className="container">
                    <div className="row">
                        <div className="col s12 loginForm">
                            <div className="row">
                                <div className="col s12 m8 offset-m2 white wrapperLogin">
                                    <div className="valign-wrapper center-align">
                                        <div className="row w-100" >
                                            {
                                                step === 3 ?

                                                    formReset()
                                                    :
                                                    <React.Fragment>
                                                        <div className="col s12">
                                                            <img src={Logo} alt="logo" />
                                                        </div>

                                                        {showStep()}

                                                        {
                                                            step < 2 ?
                                                                <div className="col s12 mt-2">
                                                                    <Link to="/"><span>Iniciar Sesión</span></Link>
                                                                </div>
                                                                : null
                                                        }
                                                    </React.Fragment>
                                            }


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default OlvidePass;