import React from "react";



function CardFondo({
    stilo,
    titulo,
    cantidad,
    banco,
    clave
}) {

    return (
       
            <div className={`cardBalance ${stilo}  row`}>
                <div className="col s10 left-align ft-14">
                    {titulo}
                </div>
                <div className="col s2 right-align">
                    <span className="badge badgeCard txtBold">Activo</span>
                </div>
                <div className="col s12 left-align mt-1 cantidad ft-28 mb-1">
                    {cantidad}
                </div>
                {
                    banco && banco.trim() !== "" ?
                    <div className="col s6 left-align ft-14">
                        {banco}
                    </div>
                    : null
                }
                {
                    clave && clave.trim() !== "" ? 
                    <div className="col s6 right-align ft-14">
                        Clabe: {clave}
                    </div>:null
                }
            </div>
    )
}


export default CardFondo;