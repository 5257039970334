
import React, {useState} from "react";
import { Outlet } from "react-router";
import { useNavigate } from "react-router";

function Perfil() {
    const navigate = useNavigate()

    return (
        <div className="container">
            <div className="row">
                <div className="col s12 left-align mt-2">
                    <h5 className="txtBold">Mi Perfil</h5>
                </div>
                <div className="col s12 mt-2">
                    <div className="row ">
                        <div className="col s3">
                            <div className="row btnSide">
                                <div className="col s12">
                                    <button className="btn waves-effect light btnAct white"
                                    onClick={()=>{
                                        navigate('personales')
                                    }}
                                    >Perfil<i className="material-icons left">person</i></button>
                                </div>
                                <div className="col s12">
                                    <button className="btn waves-effect light btnAct white"
                                    onClick={()=>{
                                        navigate('cuentas')
                                    }}><span className="truncate">Cuentas Registradas <i className="material-icons left">credit_card</i></span></button>
                                </div>
                                <div className="col s12">
                                    <button className="btn waves-effect light btnAct white"
                                    onClick={()=>{
                                        navigate('seguridad')
                                    }}>Seguridad<i className="material-icons left">security</i></button>
                                </div>
                            </div>
                        </div>
                        <div className="col s9">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )


}

export default Perfil;