import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Logo from '../assets/logo.svg'
import { setUser, toogleLoad } from "../redux/slices/appslice";
import Token from "../components/token";
import { solicitaCodigoLogin, validaCodigoLogin } from '../ws';
import FormNewPass from "./formNewPass";
import M from 'materialize-css';


function ModalPV({cambioPassDirecto}) {

    const dispatch = useDispatch();
    const appData = useSelector((state) => state.appData)
    const [muestraToken, setMuestraToken] = useState(false);
    const [codigoIncorrecto, setCodigoIncorrecto] = useState(false);
    const [muestraCambioPass, setMuestraCambioPass] = useState(false);

    useEffect(() => {
      
        if(cambioPassDirecto){
            setMuestraCambioPass(cambioPassDirecto)
        }
   
    }, [cambioPassDirecto])

    async function solCodigoLogin() {
        try {
            dispatch(toogleLoad(true));
            let result = await solicitaCodigoLogin({
                idUsuario: appData.user.IdUsuario,
                sesion: appData.user.Sesion,
                token: appData.token.access_token,
                refreshToken: appData.token.refresh_token,
                idToken: appData.idToken,
                JWT: appData.token
            });
            if (result && result.BanderaExito) {
                setMuestraToken(true);
            }
            dispatch(toogleLoad(false));
        } catch (error) {
            dispatch(toogleLoad(false));
        }
    }

    async function validaCodigoL(token) {
        try {
            dispatch(toogleLoad(true));
            let dataSend = JSON.parse(JSON.stringify({
                idUsuario: appData.user.IdUsuario,
                sesion: appData.user.Sesion,
                token: appData.token.access_token,
                refreshToken: appData.token.refresh_token,
                idToken: appData.idToken,
                JWT: appData.token
            }));
            dataSend.CodigoSeg = token;
            let result = await validaCodigoLogin(dataSend);
            if (result && result.BanderaExito) {
                setMuestraCambioPass(true);
            } else {
                setCodigoIncorrecto(true);
            }
            dispatch(toogleLoad(false));
        } catch (error) {
            dispatch(toogleLoad(false));
        }
    }

    return (
        <div id="modalPV" className="modal">

            {
                muestraCambioPass ?

                <div className="modal-content">
                    <div className="row">
                        <div className="col s12 left-align">
                            <span className="primaryText txtBold ft-14">Cambia tu contraseña.</span>
                        </div>
                        <div className="col s12">
                    <div className="divider"/>
                        </div>
                        <div className="col s12 center-align mt-2">
                            <FormNewPass callBack={()=>{
                                     M.Modal.getInstance(document.getElementById('modalPV')).close();
                            }}/>    
                        </div>
                    </div>
                </div>

                   
                        :

                        <div className="modal-content">
                            {
                                muestraToken ?
                                    <div className="row">
                                        <div className="col s12 center-align mt-1">

                                            <img src={Logo} alt="logo" />

                                        </div>
                                        <div className="col s12 center-align txtBold mt-2">

                                            Por último
                                        </div>

                                        <div className="col s12 center-align mt-1">
                                            Ingresa el código que se te envió a tu correo electrónico
                                        </div>

                                        <div className="col s12 center-align mt-1">
                                            <Token callBack={validaCodigoL} onchangeCode={() => {
                                                setCodigoIncorrecto(false);
                                            }} />
                                        </div>
                                        {
                                            codigoIncorrecto ?

                                                <div className="col s12 center-align mt-1 red-text darken-4 txtBold ft-14">
                                                    El código que ingresaste es incorrecto intenta de nuevo.
                                                </div>
                                                :

                                                null
                                        }
                                    </div>

                                    :

                                    <div className="row">
                                        <div className="col s12 center-align mt-1">

                                            <img src={Logo} alt="logo" />

                                        </div>
                                        <div className="col s12 center-align txtBold mt-2">
                                            Se enviará una contraseña a tu correo electrónico o celular registrado
                                        </div>

                                        <div className="col s12 center-align mt-2">
                                            <span className="ft-16 txtBold txt_main-dark-blue">{appData.user.email}</span>
                                        </div>

                                        <div className="col s12 center-align mt-2">
                                            <button className="btn-flat"
                                                onClick={async () => {
                                                    solCodigoLogin();
                                                }}
                                            ><span className="primaryText txtBold no_txtTransform">Continuar</span></button>
                                        </div>




                                    </div>
                            }


                        </div>
            }


                    </div>
    )
}


            export default ModalPV;