import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import { nWithCommas } from '../utils';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
   
    plugins: {
        title: {
            display: true,
            text: '',
        },
        legend:{
            position: 'bottom'
        },
        tooltip: {
            enabled: false 
          }
    },
    responsive: true,
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
            ticks: {
                
                // Include a dollar sign in the ticks
                callback: function(value, index, ticks) {
                    return '$' + nWithCommas(parseFloat(value).toFixed(0));
                },
            },

        },
        
    },
};

export  default function StakedBar({data}) {
    return <Bar options={options} data={data} />;
}
