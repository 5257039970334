import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { showModalFinSesion } from "../redux/slices/appslice";
import { cerrarSesion } from '../ws'; 
import { TEXT_FIN_SESION_INACTIVIDAD, TEXT_FIN_SESION_JWT } from '../utils'

const SesionInactiva = ({ tiempoInactividadMinutos, tiempoSesionMinutos }) => {

  const appData = useSelector((state) => state.appData)
  const dispatch = useDispatch();
  const [sesionExpirada, setSesionExpirada] = useState(false);


 

  useEffect(() => {
    let inactividadTimer;
    let sesionTimer;

    const reiniciarInactividadTimer = () => {
      clearTimeout(inactividadTimer);
      inactividadTimer = setTimeout(async () => {
        setSesionExpirada(true);
        if (appData.user) {
            await cerrarSesion(appData);
            dispatch(showModalFinSesion({ texto: TEXT_FIN_SESION_INACTIVIDAD }))
        }
      },  tiempoInactividadMinutos * 60 * 1000);
    };

    const reiniciarSesionTimer = () => {
        
      clearTimeout(sesionTimer);
      sesionTimer = setTimeout( async () => {
        
        setSesionExpirada(true);
        if (appData.user) {
            await cerrarSesion(appData);
            dispatch(showModalFinSesion({ texto: TEXT_FIN_SESION_JWT }))
        }
      }, tiempoSesionMinutos * 60 * 1000);
    };

    const reiniciarInactividadAlAccionar = () => {
      clearTimeout(inactividadTimer);
      reiniciarInactividadTimer();
    };

    const reiniciarTimers = () => {
      
      reiniciarInactividadTimer();
      reiniciarSesionTimer();
    };

    document.addEventListener('mousemove', reiniciarInactividadAlAccionar);
    document.addEventListener('keypress', reiniciarInactividadAlAccionar);
    
    
    reiniciarTimers();
    

    return () => {
      clearTimeout(inactividadTimer);
      clearTimeout(sesionTimer);
      document.removeEventListener('mousemove', reiniciarInactividadAlAccionar);
      document.removeEventListener('keypress', reiniciarInactividadAlAccionar);
    };
  }, [tiempoInactividadMinutos, tiempoSesionMinutos, appData]);

  return (
    <div>
    </div>
  );
};

export default SesionInactiva;