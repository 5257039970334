
import { nWithCommas } from "../utils";
import StakedBar from '../components/stakedBar';

function ResultCalculadora({ data, opt, helper }) {

    return (

        <div className="row" style={{paddingLeft:'14px'}}>
            <div className="col s12 left-align ft-24 labelBlueCalc txtBold">
                Pensión mensual al retiro <i className="material-icons cursorPointer linksC"
                onClick={helper}
                > info_outline</i>
            </div>
            <div className="col s6">
                <div className="row">
                    <div className="col s12 ">
                        <div className={`card  br-8 ${opt=== 1 ?  'cardSA73' : 'cardSA97'}`}>
                            <div className="card-content white-text">
                                <p className="ft-14">Sin aportaciones:</p>
                                <p className="ft-32">$ {nWithCommas(parseFloat(data.sinAportaciones).toFixed(2))}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col s6">
                <div className="row">
                    <div className="col s12 ">
                        <div className={`card  br-8 ${opt=== 1 ? 'cardCA73' : 'cardCA97'}`}>
                            <div className="card-content white-text">
                                <p className="ft-14">Con aportaciones:</p>
                                <p className="ft-32">$ {nWithCommas(parseFloat(data.conAportaciones).toFixed(2))}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col s12 wrapperGraficaCalc">
                <StakedBar data={
                    {
                        labels:  opt===1 ? ['Pensión IMSS', 'Con Aportaciones'] : ['Afore', 'Aportaciones'],
                        datasets: [
                            {
                                label: opt===1 ? 'Pensión IMSS' : 'Afore',
                                data: [data.sinAportaciones, data.sinAportaciones ],
                                backgroundColor: opt === 1 ? 'rgb(203, 171, 206)' : 'rgb(255, 209, 90)',
                            },
                            {
                                label: 'Aportación',
                                data: [data.sinAportaciones, data.conAportaciones],
                                backgroundColor: opt === 1 ? 'rgb(162, 94, 181)': 'rgb(255, 164, 0)',
                            },
                        ],
                    }
                } />
            </div>
            <div className="col s12 mt-3">
                <div className="row br-8 grayBg rowResultCalc">
                    <div className="col s6 left-align  txt_main-dark-blue ft-14 txtBold">
                        Edad de jubilación
                    </div>
                    <div className="col s6 right-align  txt_main-dark-blue ft-14 txtBold">
                        {data.edadJubilacion}
                    </div>
                </div>
            </div>

           
            <div className="col s12 mt-1">
                <div className="row br-8 grayBg rowResultCalc">
                    <div className="col s6 left-align  txt_main-dark-blue ft-14 txtBold">
                        {opt === 1 ? 'Aportaciones': 'Ahorros en Afore'}
                    </div>
                    <div className="col s6 right-align  txt_main-dark-blue ft-14 txtBold">
                        ${nWithCommas(parseFloat(opt === 1 ? data.aportaciones : data.saldoAfore).toFixed(2))}
                    </div>
                </div>
            </div>

            {
                opt !== 1 ? 
                <div className="col s12 mt-1">
                <div className="row br-8 grayBg rowResultCalc">
                    <div className="col s6 left-align txt_main-dark-blue ft-14 txtBold">
                    Total Aportaciones
                    </div>
                    <div className="col s6 right-align txt_main-dark-blue ft-14 txtBold">
                        ${nWithCommas(parseFloat(data.aportacion).toFixed(2))}
                    </div>
                </div>
            </div>

                : null
            }

            <div className="col s12 mt-1">
                <div className="row br-8 grayBg rowResultCalc">
                    <div className="col s6 left-align txt_main-dark-blue ft-14 txtBold">
                    Total de ahorro al retiro
                    </div>
                    <div className="col s6 right-align txt_main-dark-blue ft-14 txtBold">
                        ${nWithCommas(parseFloat(data.totalAhorro).toFixed(2))}
                    </div>
                </div>
            </div>


        </div>
    )

}

export default ResultCalculadora;